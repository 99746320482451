export interface Input {
	value: string
}

<legend>
	Time in
	<a href=`https:///en.wikipedia.org/wiki/${input.value}`>${input.value}</a>
</legend>
<noscript>Enable JavaScript to see my time.</noscript>
<let/time />
<effect() {
	time = new Date()
	const interval = setInterval(() => {
		time = new Date()
	}, 1000)
	return () => clearInterval(interval)
} />
<if=time>
	<time>
		${time.toLocaleString([], {
			timeZone: input.value,
			timeStyle: "short",
		})}
	</time>
	<!-- TODO: Add clock background -->
</if>

<style>
	#clock time {
		font-family: mono;
		font-size: x-large;
	}
	/* I think uBlock Origin renames noscript to span for some reason */
	#clock noscript,
	#clock span {
		color: GrayText;
	}
	#clock {
		/* aspect-ratio: 1/1; */
		align-content: center;
		text-align: center;
	}
</style>
