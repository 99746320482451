export interface Input {
	/** The Discord user ID to monitor */
	value: string
	renderBody?: Marko.Body<[{}]>
}
<let/presence />
<effect() {
	const websocket = new WebSocket("wss://api.lanyard.rest/socket")
	let heartbeat: [heartbeat?: ReturnType<typeof setInterval>] = [undefined]
	// Marko's compiler will move the following function outside of this <effect>. This is a bug and it means we can't use `heartbeat` or `websocket` in the function.
	// As a workaround we'll set `heartbeat` as a global variable and use `this` for `websocket`.
	globalThis.heartbeat = heartbeat
	websocket.onmessage = function (event) {
		const packet = JSON.parse(event.data)
		if (packet.op === 1) {
			if (heartbeat[0]) clearInterval(heartbeat[0])
			heartbeat[0] = setInterval(
				() => this.send('{"op":3}'),
				packet?.d?.heartbeat_interval ?? 30000,
			)
			this.send(
				`{"op":2,"d":{"subscribe_to_ids":[${JSON.stringify(input.value)}]}}`,
			)
		} else if (packet.op === 0) {
			if (packet.t === "INIT_STATE") {
				presence = packet.d[input.value]
			} else if (packet.t === "PRESENCE_UPDATE") {
				presence = packet.d
			}
		}
	}

	// Thankfully, this function is not moved outside of the <effect>
	$signal.onabort = () => {
		websocket.close()
		clearInterval(heartbeat[0])
		delete globalThis.heartbeat
	}
} />

<if=presence><${input.renderBody} ...presence /></if>
